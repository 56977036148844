import React from "react";

function Events() {
    return (
        <>
            <div style={{ marginTop: '120px' }}>
                <div className='background_contain'>
                    <div className="d-flex justify-content-center">
                        <div className="col-10">
                            <img style={{ width: '50%', height: '90%' }} src={require('../../Assets/images/adroitEvent.jpeg')}></img>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Events